window._ = require('lodash');
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');
// console.log("settings", pusher_settings, window.pusher_settings)
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: pusher_setting.pusher_app_key,
    cluster: pusher_setting.pusher_cluster,
    forceTLS: (pusher_setting.force_tls == 1)
});

